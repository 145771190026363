/* eslint-disable */
import Dangerous from 'dangerous-components'
import React from 'react'
import { string, arrayOf } from 'prop-types'

import { LeadForm, BaseForm } from 'form'
import { InputName, InputPhone, InputEmail, Description } from 'UI/form'

const DemonsteationRequest = ({
  formTag,
  google,
  goal,
  pageSlug,
  successMessage,
  title,
  successGoal,
}) => {
  const slug = pageSlug || '/'

  return (
    <div>
      <div
        className="home-contact__background js-parallaxTrigger"
        data-parallaxshift="200"
      >
        <span className="js-parallaxElem -noParallax" />
        <img className="mobile-hide" src="/images/contact-background.svg" />
        <img
          className="mobile-show"
          src="/images/contact-background-mobile.png"
        />
      </div>

      <div className="container">
        <Dangerous
          html={title}
          as="div"
          className="home-title home-contact__title animate"
        />

        <div className="home-contact__left">
          <div className="home-contact__text animate">
            Наши специалисты готовы провести демонстрацию программы
            и&nbsp;представить ее&nbsp;на&nbsp;самостоятельное ознакомление,
            рассчитать стоимость внедрения и&nbsp;сроки окупаемости,
            проконсультировать о&nbsp;лучших практиках управления запасами.
          </div>
          <div className="home-contact-block animate">
            <div className="home-contact-block__item">
              <a href="tel:+74959297105" className="home-contact-block__phone">
                +7 (495) 929-71-05
              </a>
              <a href="https://telegram.me/fnow_bot">
                <img src="/images/telegram.svg" alt="Telegram" />
              </a>
            </div>
            <div className="home-contact-block__item">
              <a
                href="mailto:info@forecastnow.ru"
                className="home-contact-block__email"
              >
                info@forecastnow.ru
              </a>
            </div>
            <a href="/contacts" className="home-contact-block__link">
              Все контакты
            </a>
          </div>

          {/* <div>
            <p>Отвечаем на вопросы любым удобным способом:</p>
            <div className="request__messangers">
              <Messangers />
            </div>
            <a href="mailto:info@forecastnow.ru" className="request__mail">
              info@forecastnow.ru
            </a>
             <br />
             <a href="/contacts" className="request__contacts">
               Все контакты
             </a>
          </div> */}
        </div>
        <div className="home-contact__right">
          <LeadForm
            formTag={formTag}
            formType="main"
            google={google}
            goal={goal}
            pageSlug={slug}
            successMessage={successMessage}
            successGoal={successGoal}
            submitCaption="Отправить"
            formTitle={title}
            formBody="Наши специалисты готовы провести демонстрацию программы
                      и&nbsp;представить ее&nbsp;на&nbsp;самостоятельное ознакомление,
                      рассчитать стоимость внедрения и&nbsp;сроки окупаемости,
                      проконсультировать о&nbsp;лучших практиках управления запасами."
          >
            <BaseForm submitCaption="Оставить заявку">
              <InputName />
              <InputPhone />
              <InputEmail />
              <Description />
            </BaseForm>
          </LeadForm>
          <div class="form-personal-block">
            Отправляя форму, вы даете{' '}
            <a href="/personal"> согласие на обработку персональных данных </a>
          </div>
        </div>

        <div className="home-contact-card animate">
          <div className="home-contact-card__title">
            Телеграм-канал и рассылка
          </div>
          <div className="home-contact-card__text">
            Актуальные материалы по теме управления товарными запасами и наши
            исследования.
          </div>
          <div className="home-contact-card__bottom">
            <a
              className="home-link home-contact-card__link"
              href="https://t.me/forecastnow"
            >
              Канал
              <strong> @forecastnow</strong>
            </a>
            <a
              className="home-link home-contact-card__link popup-open"
              href="#popup-email"
            >
              Подписаться по
              <strong> email</strong>
            </a>
          </div>
        </div>
        <div className="home-contact-card animate">
          <div className="home-contact-card__title">Учебный курс</div>
          <div className="home-contact-card__text">
            Управление товарными запасами для специалистов по закупкам.
          </div>
          <div className="home-contact-card__bottom">
            <a
              className="home-link home-contact-card__link"
              href="/inventory-management-course"
            >
              Программа и запись на курс
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

DemonsteationRequest.propTypes = {
  formTag: string.isRequired,
  google: arrayOf(string),
  goal: string,
  pageSlug: string,
  successMessage: string,
  title: string,
  successGoal: string,
}

DemonsteationRequest.defaultProps = {
  google: null,
  goal: null,
  successGoal: null,
  successMessage: '',
  pageSlug: '/',
  title:
    'Узнайте, чем программа Forecast NOW!<br/>полезна именно вашей компании',
}

export default DemonsteationRequest
/* eslint-enable */
